<template>
  <v-form @submit.prevent="submitMethod">
    <v-text-field
      autofocus
      label="Recipient"
      v-model="recipient"
      :rules="emailValid"
      validate-on-blur
    ></v-text-field>

    <v-text-field
      label="Subject"
      v-model="subject"
      :rules="subjectValid"
      validate-on-blur
    ></v-text-field>

    <Wysiwyg v-model="content" />

    <v-text-field
      label="View Proposal Button Text"
      hint="This is the text on the button your client will click to view the emailed proposal."
      v-model="buttonText"
      :rules="buttonTextValid"
    ></v-text-field>

    <v-switch
      v-model="cc"
      :label="`CC ${ ccEmail }`"
    ></v-switch>
    <hidden-submit-button />
  </v-form>
</template>

<script>
import HiddenSubmitButton from '@/components/common/HiddenSubmitButton.vue';
import Wysiwyg from '@/components/inputs/wysiwyg';
import { required, email } from '@/utils/validation';

export default {
  name: 'SendProposalEmailInterface',

  components: {
    Wysiwyg,
    HiddenSubmitButton,
  },

  data() {
    let recipient = '';

    if (this.$store.state.proposals.current.customers[0]) {
      recipient = this.$store.state.proposals.current.customers[0].email;
    }

    return {
      sending: false,
      recipient: recipient,
      subject: 'Proposal',
      content: 'We’re excited to work with you!',
      cc: false,
      buttonText: 'View Proposal',
    };
  },

  computed: {
    saveBtnEnabled() {
      if (this.sending) {
        return false;
      }

      return [ 
        ...this.emailValid, ...this.subjectValid, ...this.buttonTextValid,
      ].filter(v => v !== true).length === 0;
    },

    ccEmail() {
      const owner = this.$store.state.proposals.current.owner;

      return owner ? owner.user_email : '';
    },

    buttonTextValid() {
      return [
        required(this.buttonText) || 'Please provide text for the view proposal button.',
      ];
    },

    emailValid() {
      const recipient = this.recipient || '';

      return [
        required(recipient) || 'Please provide an email address.',
        email(recipient) || 'Please provide a valid email address.',
      ];
    },

    subjectValid() {
      return [
        required(this.subject) || 'Please provide a subject.',
      ];
    },
  },

  methods: {
    save(close) {
      this.sending = true;

      this.$store.dispatch('sendProposalEmail', {
        email: this.recipient,
        subject: this.subject,
        content: this.content,
        cc: this.cc ? this.ccEmail : '',
        buttonText: this.buttonText,
        onSuccess: () => {
          this.sending = false;
          this.$store.commit('notify', {
            color: 'success',
            message: 'Email Sent',
            timeout: 5000,
          });

          this.redirectToProposal();
          close();
        },
        onError: (message) => {
          this.sending = false;
          this.$store.commit('notify', {
            color: 'error',
            message: message,
            timeout: 5000,
          });
        },
      });
    },

    onCancel() {
      this.redirectToProposal();
    },

    redirectToProposal() {
      if (this.$route.name !== 'Proposal') {
        this.$router.push({ name: 'Proposal', params: { id: this.$store.state.proposals.current.id } });
      }
    },

    submitMethod() {
      if (this.saveBtnEnabled) {
        this.$emit('enterPress');
      }
    },
  },
};
</script>

<style lang="scss">
  .full-width {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: 16px;
    max-width: none;
  }
</style>
