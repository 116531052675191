var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submitMethod($event)
        }
      }
    },
    [
      _c("v-text-field", {
        attrs: {
          autofocus: "",
          label: "Recipient",
          rules: _vm.emailValid,
          "validate-on-blur": ""
        },
        model: {
          value: _vm.recipient,
          callback: function($$v) {
            _vm.recipient = $$v
          },
          expression: "recipient"
        }
      }),
      _c("v-text-field", {
        attrs: {
          label: "Subject",
          rules: _vm.subjectValid,
          "validate-on-blur": ""
        },
        model: {
          value: _vm.subject,
          callback: function($$v) {
            _vm.subject = $$v
          },
          expression: "subject"
        }
      }),
      _c("Wysiwyg", {
        model: {
          value: _vm.content,
          callback: function($$v) {
            _vm.content = $$v
          },
          expression: "content"
        }
      }),
      _c("v-text-field", {
        attrs: {
          label: "View Proposal Button Text",
          hint:
            "This is the text on the button your client will click to view the emailed proposal.",
          rules: _vm.buttonTextValid
        },
        model: {
          value: _vm.buttonText,
          callback: function($$v) {
            _vm.buttonText = $$v
          },
          expression: "buttonText"
        }
      }),
      _c("v-switch", {
        attrs: { label: "CC " + _vm.ccEmail },
        model: {
          value: _vm.cc,
          callback: function($$v) {
            _vm.cc = $$v
          },
          expression: "cc"
        }
      }),
      _c("hidden-submit-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }